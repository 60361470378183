import _ from 'lodash'
import React from 'react'
import { withSnackbar } from 'notistack'
import { navigate } from 'gatsby'
import queryString from 'query-string'

import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

// import Backend from '../backend/backend'
import Colors from '../utils/colors'
// import google from '../backend/google'
import { CustomIcon, IconWrapper } from '../components/icon'
import Page from './page'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  drawerContainer: {
    overflow: 'auto',
    height: '100%',
  },
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  loading: {
    textAlign: 'center',
    margin: 10,
  },
  content: {
    padding: theme.spacing(1),
  },
}))

function Account({ location }) {
  const classes = useStyles()
  const [currentTabParams, setCurrentTabParams] = React.useState(null)
  const [isLoading, setLoading] = React.useState(true)
  const [isSignedIn, setSignedIn] = React.useState(false)
  const [orgs, setOrgs] = React.useState([])
  const [view, setView] = React.useState(null)

  React.useEffect(() => {
    // Set current tab
    setCurrentTabParams(queryString.parse(location.search))

    // Check authentication
    // google.signIn(() => {
    //   const signedIn = google.isSignedIn()
    //   setSignedIn(signedIn)
    //   if (signedIn) {
    //     Backend.getOrgsForUser().then(userOrgs => {
    //       setOrgs(userOrgs)
    //       setLoading(false)
    //     })
    //   }
    // }, true)
  }, [location])

  const tabs = [
    {
      label: 'Getting Started',
      icon: 'lightbulb',
      component: null,
      params: { view: 'setup' },
      ignoreLogin: true,
    },
    {
      label: 'Dashboard',
      icon: 'dashboard',
      component: null,
      params: { view: 'dashboard' },
    },
    {
      label: 'Settings',
      icon: 'settings',
      component: null,
      params: { view: 'settings' },
    },
  ]

  function renderOrg(org) {
    const orgTabs = [
      {
        label: org.name,
        avatar: org.img,
        component: null,
        params: { view: 'info', org: org.id },
      },
      {
        label: 'Stickers',
        icon: 'sticker',
        component: null,
        params: { view: 'stickers', org: org.id },
        nested: true,
      },
      {
        label: 'Campaigns',
        icon: 'money',
        component: null,
        params: { view: 'campaigns', org: org.id },
        nested: true,
      },
    ]
    return <List>{_.map(orgTabs, renderTab)}</List>
  }

  function renderTab(tab, idx) {
    return (
      <ListItem
        button
        key={idx}
        className={tab.nested ? classes.nested : null}
        disabled={tab.ignoreLogin ? false : !isSignedIn}
        onClick={() => {
          if (tab.params) {
            // Set URL
            const newURL =
              '/account?' +
              _.join(
                _.map(tab.params, (v, k) => `${k}=${v}`),
                '&'
              )
            navigate(newURL)
            setCurrentTabParams(tab.params)

            // Set component
            const component = tab.component
            if (_.isFunction(component)) {
              setView(component(tab.params))
            } else {
              setView(null)
            }
          }
        }}
        selected={_.isEqual(tab.params, currentTabParams)}
      >
        {tab.icon ? (
          <ListItemIcon>
            <CustomIcon icon={new IconWrapper(tab.icon)} />
          </ListItemIcon>
        ) : (
          <ListItemAvatar>
            {tab.avatar ? (
              <Avatar
                alt={tab.label}
                src={tab.avatar}
                className={classes.avatar}
              />
            ) : (
              <Avatar
                aria-label="logo"
                style={{ backgroundColor: Colors.random() }}
              >
                {tab.label[0]}
              </Avatar>
            )}
          </ListItemAvatar>
        )}
        <ListItemText primary={tab.label} />
      </ListItem>
    )
  }

  function content() {
    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerContainer}>
            {/** General tabs */}
            <List>{_.map(tabs, renderTab)}</List>
            <Divider />

            {/** Organizations */}
            {_.map(orgs, (org, idx) => {
              return (
                <div key={idx}>
                  {renderOrg(org)}
                  {idx + 1 < _.size(orgs) ? <Divider /> : null}
                </div>
              )
            })}

            {/** Loading indicator */}
            {isLoading ? (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            ) : null}
          </div>
        </Drawer>
        <div className={classes.content}>{view}</div>
      </div>
    )
  }

  return <Page name="Account" content={content()} />
}

export default withSnackbar(Account)
